import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useDispatch } from 'react-redux';
import { SCREENS } from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { Button, Col, Container, Row, Label, Input } from 'reactstrap';
import { showErrorToast } from 'toast';
import CustomCollapse from 'components/custom/CustomCollapse';
import { getAppointment } from 'redux/actions/scheduler/index';

const Service = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const [serviceGroup, setServiceGroup] = useState([]);
  const [currSelectedAppointmentCodeServiceLane, setCurrSelectedAppointmentCodeServiceLane] =
    useState('');
  const [selectedAppointmentServices, setSelectedAppointmentServices] = useState(
    currDataState.services ?? [],
  );

  const [otherRequest, setOtherRequest] = useState(currDataState.otherRequest || '');

  useEffect(() => {
    if (isEmpty(selectedAppointmentServices)) {
      setCurrSelectedAppointmentCodeServiceLane('');
    } else {
      setCurrSelectedAppointmentCodeServiceLane(
        selectedAppointmentServices[0].appointmentCode.serviceLane,
      );
    }
  }, [selectedAppointmentServices]);

  const handleAddToSelectedService = (appointmentCode) => {
    const selectedServiceLane = appointmentCode.serviceLane;
    if (isEmpty(selectedAppointmentServices)) {
      setCurrSelectedAppointmentCodeServiceLane(selectedServiceLane);
    }
    setSelectedAppointmentServices([
      ...selectedAppointmentServices,
      {
        appointmentCode: appointmentCode,
      },
    ]);

    if (
      ((currSelectedAppointmentCodeServiceLane === 'main' ||
        currSelectedAppointmentCodeServiceLane === 'express') &&
        selectedServiceLane === 'body') ||
      (currSelectedAppointmentCodeServiceLane === 'body' &&
        (selectedServiceLane === 'main' || selectedServiceLane === 'express'))
    ) {
      return showErrorToast({
        message: 'You cannot schedule main/express and body services together.',
      });
    }
  };

  const handleRemoveService = (serviceRow) => {
    setSelectedAppointmentServices(
      selectedAppointmentServices.filter((service) => service.appointmentCode.id !== serviceRow.id),
    );
  };

  const handleUpdateService = (id) => (key, value) => {
    const selectedServicesDeepCopy = cloneDeep(selectedAppointmentServices);
    let index = selectedServicesDeepCopy.findIndex((item) => item.appointmentCode.id === id);
    selectedServicesDeepCopy[index][key] = value;
    setSelectedAppointmentServices(selectedServicesDeepCopy);
  };

  const handleOnChange = (e) => {
    setOtherRequest(e.target.value);
  };

  const handleNextScreen = () => {
    const currStateDeepCopy = cloneDeep(currDataState);
    currStateDeepCopy.services = selectedAppointmentServices;
    currStateDeepCopy.otherRequest = otherRequest;
    setCurrDataState(currStateDeepCopy);
    setActiveScreen(SCREENS.calenderScheduler);
  };

  const selectLabel = useMemo(() => {
    return selectedAppointmentServices.length <= 0
      ? 'Select Services'
      : 'Select additional Services. Click next when complete';
  }, [selectedAppointmentServices]);

  const fetchAppGroup = async () => {
    const response = await dispatch(getAppointment());
    if (response) {
      setServiceGroup(response.results);
    }
  };

  useEffect(() => {
    fetchAppGroup();
  }, [dispatch]);

  const checkedItems = useMemo(() => {
    return selectedAppointmentServices.map((service) => service.appointmentCode.id);
  }, [selectedAppointmentServices]);

  return (
    <Fragment>
      <Container className="pb-4">
        <Col className="text-center pb-2">
          <h1 className="pb-1">
            {`What does your ${
              currDataState.vehicleInformation.currModel
                ? currDataState.vehicleInformation.currModel
                : 'vehicle'
            } need?`}
          </h1>
        </Col>
        <Label htmlFor="Search" className="h6">
          {selectLabel}
        </Label>
        <CustomCollapse
          selectedItems={checkedItems}
          collapseItems={serviceGroup}
          handleUpdateService={handleUpdateService}
          handleAddToSelectedService={handleAddToSelectedService}
          handleRemoveService={handleRemoveService}
        />
        <Label className="h6">Other Requests</Label>
        <Row className="mb-2 d-flex align-items-center p-0 flex-column flex-sm-row">
          <Col className="flex-grow-1">
            <Input
              defaultValue={currDataState.otherRequest}
              onChange={(e) => handleOnChange(e)}
              placeholder=""
              type="textarea"
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className={generateClassNameForContentPlacement('end')}>
            <Button
              className="nav-btn next px-2"
              disabled={isEmpty(selectedAppointmentServices)}
              color="success"
              onClick={handleNextScreen}
            >
              <span
                style={{
                  paddingRight: '10px',
                }}
              >
                Next
              </span>
              <ChevronRight size={16} />
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Service;
