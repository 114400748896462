import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useApiClientContext } from 'ApiClientProvider';
import {
  SCREENS,
  PREV,
  NEXT,
  NOW_LOADING,
  END_LOADING,
} from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Card, CardBody, Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { showErrorToast } from 'toast';

const CarSelection = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.dashboard.settings);
  const { appointmentInfo } = currDataState;
  const [transportationTypes, setTransportationTypes] = useState([]);
  const [selectedTransportationType, setSelectedTransportationType] = useState(
    appointmentInfo.selectedTransportationType ?? {},
  );
  const [transporationToggle, setTransportationToggle] = useState(true);
  const { schedulerApi } = useApiClientContext();
  useEffect(() => {
    (async () => {
      dispatch({ type: NOW_LOADING });
      try {
        const response = await schedulerApi.get(`/transportation-type`);
        const { results } = response.data;
        const externalTypes = results?.filter((item) => !item.internal);
        setTransportationTypes(externalTypes);
      } catch (error) {
        showErrorToast(error);
      } finally {
        dispatch({ type: END_LOADING });
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (transporationToggle) {
      setSelectedTransportationType(transportationTypes[0]);
    }
  }, [transporationToggle, transportationTypes]);

  const handleWindowChange = (window) => {
    const currDataStateDeepCopy = cloneDeep(currDataState);
    currDataStateDeepCopy.transportInformation = {
      selectedTransportationType,
    };
    setCurrDataState(currDataStateDeepCopy);
    if (window === PREV) {
      setActiveScreen(SCREENS.calenderScheduler);
    } else if (window === NEXT) {
      setActiveScreen(SCREENS.customerInformation);
    }
  };

  const handleTransportationChangeNo = useCallback(() => setTransportationToggle(false), []);
  const handleTransportationChangeYes = useCallback(() => setTransportationToggle(true), []);

  const handleNext = () => {
    if (transporationToggle || selectedTransportationType) {
      handleWindowChange(NEXT);
    } else {
      showErrorToast('Please select transportation type.');
    }
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col xs={12} className="text-center mb-1 font-weight-light">
                Will you be waiting at the dealership for your service?
              </Col>
              <Col xs={12} className="text-center">
                <Button
                  className="mr-2"
                  color="primary"
                  size="small"
                  outline={transporationToggle}
                  onClick={handleTransportationChangeNo}
                >
                  No
                </Button>
                <Button
                  size="small"
                  color="success"
                  outline={!transporationToggle}
                  onClick={handleTransportationChangeYes}
                >
                  Yes
                </Button>
              </Col>
            </Row>
            {!transporationToggle && (
              <Row className="pb-1 pt-1">
                <Col>
                  <FormGroup>
                    <Label>Select Transportation Type:</Label>
                    <Select
                      placeholder="Select Transportation Type"
                      value={transportationTypes.find(
                        (transportType) => transportType.id === selectedTransportationType.id,
                      )}
                      isSearchable={true}
                      options={transportationTypes}
                      getOptionLabel={(transportType) => transportType.title}
                      getOptionValue={(transportType) => transportType}
                      onChange={(transportationType) => {
                        setSelectedTransportationType(transportationType);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <ReactQuill
                  className="mt-2"
                  value={settings?.schedulerBenefits ?? ''}
                  readOnly
                  theme="bubble"
                  modules={{ toolbar: null }}
                />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Card className="pb-4">
        <CardBody>
          <Container>
            <Row>
              <Col className={generateClassNameForContentPlacement('start')}>
                <Button
                  className="nav-btn px-2"
                  outline
                  color="primary"
                  onClick={() => handleWindowChange(PREV)}
                >
                  <ChevronLeft size={16} /> Back
                </Button>
              </Col>
              <Col className={generateClassNameForContentPlacement('end')}>
                <Button
                  className="nav-btn next px-2"
                  color="success"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  <span
                    style={{
                      paddingRight: '10px',
                    }}
                  >
                    Next
                  </span>
                  <ChevronRight size={16} />
                </Button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CarSelection;
