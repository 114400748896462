import { NOW_LOADING, END_LOADING } from 'app/SchedulerDashboard/utils/constants';
import clientApi from 'clientApi';
import { handleError } from 'redux/actions/dashboard/index';

export const getAppointmentGroup = () => async (dispatch) => {
  dispatch({ type: NOW_LOADING });
  try {
    const response = await clientApi.getAppointmentGroup('/appointment-code-group');
    if (response.status === 200) {
      dispatch({ type: 'RECEIVE_APPOINTMENT_GROUP', data: response.data });
      return response.data;
    }
  } catch (err) {
    dispatch(handleError(err));
  } finally {
    dispatch({ type: END_LOADING });
  }
};

export const getAppointment = () => async (dispatch) => {
  dispatch({ type: NOW_LOADING });
  try {
    const response = await clientApi.getAppointment('/appointment-code');
    if (response.status === 200) {
      dispatch({ type: 'RECEIVE_APPOINTMENT', data: response.data });
      return response.data;
    }
  } catch (err) {
    dispatch(handleError(err));
  } finally {
    dispatch({ type: END_LOADING });
  }
};
